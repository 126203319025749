import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CircleFlag, getPhoneNumberExample, ISO3166Alpha2 } from '@bits/i18n';
import {
  formatPhoneNumberIntl as _formatPhoneNumberIntl,
  formatPhoneNumber,
  getCountries,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';

import { ThemedInput, ThemedSearchableSelect } from '.';
import { cn } from '../utils/cn';
import { countryCodeToName } from '../utils/country-code';

export type ThemedPhoneNumberInputProps = {
  label?: string;
  error?: string;
  hint?: string;
  value?: string;
  onValueChange?: (v?: string) => void;
  className?: string;
  defaultCountryCode?: ISO3166Alpha2;
  language?: string;
  placeholder: string;
  emptyText: string;
  disabled?: boolean;
};

export const validatePhoneNumber = (phoneNumber?: string | null) =>
  phoneNumber &&
  // Only allow -, (, ), +, digits and spaces, then verify the possibility
  /^[0-9\-\(\)\s\+]+$/.test(phoneNumber) &&
  isPossiblePhoneNumber(phoneNumber);

export const formatPhoneNumberIntl = (phoneNumber: string) =>
  _formatPhoneNumberIntl(phoneNumber);

type Countries = ReturnType<typeof getCountries>[number];

export const ThemedPhoneNumberInput = forwardRef<
  HTMLInputElement,
  ThemedPhoneNumberInputProps
>(
  (
    {
      defaultCountryCode = 'SE',
      value,
      language = 'en',
      onValueChange,
      label,
      error,
      hint,
      emptyText,
      placeholder,
      disabled,
    }: ThemedPhoneNumberInputProps,
    inputRef
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(inputRef, () => innerRef.current!, []);

    const parsedNumber = parsePhoneNumber(value || '');

    const [callCode, setCallCode] = useState<string>(
      parsedNumber?.countryCallingCode ||
        getCountryCallingCode(defaultCountryCode as Countries)
    );
    const [countryCode, setCountryCode] = useState<ISO3166Alpha2>(
      parsedNumber?.country || defaultCountryCode
    );

    const [numberWithoutCallCode, setNumberWithoutCallCode] = useState<string>(
      value?.replace(`+${callCode}`, '') || ''
    );

    const mappedCallCodes = useMemo(() => mapCallCodes(language), [language]);
    const example = getPhoneNumberExample(countryCode as ISO3166Alpha2);
    const formattedExample = formatPhoneNumber('+' + callCode + example).trim();

    useEffect(() => {
      // If there is something in the number input
      if (numberWithoutCallCode) {
        onValueChange?.(`+${callCode}${numberWithoutCallCode}`);
      } else {
        // If there is nothing in the number input, the value nahhh
        onValueChange?.('');
      }
    }, [callCode, numberWithoutCallCode]);

    useEffect(() => {
      if (!value) {
        setCallCode(getCountryCallingCode(defaultCountryCode as Countries));
        setCountryCode(defaultCountryCode);
        setNumberWithoutCallCode('');
        return;
      }

      const parsed = parsePhoneNumber(value || '');

      if (
        value &&
        parsed?.countryCallingCode &&
        innerRef.current &&
        document.activeElement !== innerRef.current
      ) {
        setCallCode(parsed.countryCallingCode);

        // We use the country code from the parsed number if it exists,
        // otherwise we choose the firstr country code we can find from the calling code list
        const countryCode =
          (parsed.country as ISO3166Alpha2) ||
          callingCodes.find(
            (cc) => cc.callingCode === parsed.countryCallingCode
          )?.countryCode;

        setCountryCode((countryCode as ISO3166Alpha2) || defaultCountryCode);
        setNumberWithoutCallCode(
          value.replace(`+${parsed.countryCallingCode}`, '')
        );
      }
    }, [value]);

    return (
      <div className="grid gap-1">
        <p
          className={cn(
            'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor',
            error && 'text-theme-inputs-errorColor'
          )}
        >
          {label}
        </p>
        <div className="@container flex gap-2">
          <div className="@w-md:w-[140px] w-[128px] shrink-0">
            <ThemedSearchableSelect
              values={[countryCode]}
              emptyText={emptyText}
              placeholder={placeholder}
              error={!!error}
              onValuesChange={([v]) => {
                setCountryCode(v as ISO3166Alpha2);
                setCallCode(getCountryCallingCode(v as Countries));
              }}
              disabled={disabled}
              options={mappedCallCodes}
              renderOption={(option) => (
                <div className="flex w-full items-center gap-2 overflow-hidden">
                  <CircleFlag
                    className="size-5 shrink-0"
                    countryCode={option.value}
                  />
                  <div className="w-full truncate">
                    <span>{option.label}</span>
                  </div>
                </div>
              )}
              renderValues={([countryCode]) => {
                const callCode = getCountryCallingCode(
                  countryCode as Countries
                );
                return (
                  <div className="text-theme-inputs-fontSize flex items-center gap-2">
                    <CircleFlag
                      className="@w-md:size-5 size-4 shrink-0"
                      countryCode={countryCode as string}
                    />
                    (+{callCode})
                  </div>
                );
              }}
            />
          </div>

          <div className="w-full">
            <ThemedInput
              type="tel"
              ref={innerRef}
              onValueChange={(v) => setNumberWithoutCallCode(v)}
              value={numberWithoutCallCode}
              error={!!error}
              aria-label={label}
              placeholder={formattedExample}
              disabled={disabled}
              // onBlur={handleBlur}
            />
          </div>
        </div>

        {(hint || error) && (
          <p
            className={cn(
              'text-theme-inputs-labelTextColor text-theme-inputs-hintAndErrorFontSize',
              error &&
                'text-theme-inputs-errorColor text-theme-inputs-hintAndErrorFontSize'
            )}
          >
            {error || hint}
          </p>
        )}
      </div>
    );
  }
);

ThemedPhoneNumberInput.displayName = 'ThemedPhoneNumberInput';

const callingCodes = getCountries().map((countryCode) => ({
  countryCode: countryCode,
  callingCode: getCountryCallingCode(countryCode),
}));

const mapCallCodes = (language: string) =>
  getCountries()
    .map((countryCode) => ({
      label: `(+${getCountryCallingCode(countryCode)}) ${countryCodeToName(
        countryCode,
        language
      )}`,
      value: countryCode,
      data: {
        name: countryCodeToName(countryCode, language) || '',
      },
    }))
    .sort((a, b) => a.data.name.localeCompare(b.data.name));
